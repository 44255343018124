<template>
  <div class="nav-header">
    <div class="back-btn" @click="goBack"><img src="@/assets/back.png"></div>
    <div v-if="showRight" class="bind-card" @click="goBindCard">绑定新卡</div>
  </div>
</template>
<script>
import { hasCards } from "@/service";
export default {
  name: "NavHeader",
  props: {
    showRight: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: String,
    },
  },
  methods: {
    goBack() {
      if (this.backUrl) {
        this.$router.push(this.backUrl);
      } else {
        this.$router.go(-1);
      }
    },
    async goBindCard() {
      const merchantId = this.$store.state.merchantId;
      const hasCard = await hasCards(merchantId);
      if (hasCard) {
        this.$router.push({ name: "bindCard" });
      } else {
        this.$router.push({
          name: "VipViewer",
          params: {
            tab: "myCard",
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
// .nav-header {
//   & + * {
//     margin-top: 0.5rem;
//   }
// }
</style>
<style lang="scss" scoped>
.nav-header {
  color: #111;
  padding: 0 0.15rem;
  font-weight: 600;
  line-height: 0.5rem;
  height: .5rem;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  .back-btn{
    width: 0.17rem;
    height: 0.17rem;
    float: left;
    overflow: hidden;
    line-height: 0;
    margin-top: 0.15rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .anticon {
    font-size: 0.16rem;
  }
  .bind-card {
    color: $active-color;
    float: right;
    font-size: 0.12rem;
  }
}
</style>
