/*
 * @Author: weidonghua
 */
export const formatCardNo = (value = "") => {
  const cardNo = String(value);
  let result = "";
  for (let i = 0; i < cardNo.length; i++) {
    if (i % 4 === 0) {
      result += " ";
    }
    result += cardNo.charAt(i);
  }
  return result;
};

export const dateFormat = (date, fmt = "YYYY/mm/dd HH:MM") => {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};

export const isIOSBrowser = () => {
  const userAgent = window.navigator.userAgent;
  return (
    userAgent.indexOf("iPhone") > -1 && userAgent.indexOf("MicroMessenger") < 0
  );
};
