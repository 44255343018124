<template>
  <div class="manage-healthCard">
    <nav-header></nav-header>
    <div class="mc_top">
      <span>{{ title }}</span>
    </div>
    <div class="mc_middle">
      <div class="mc_cardBox">
        <card :value="cardInfo"></card>
      </div>
      <div class="mc_cardInfo">
        <div class="mc_infoLeft">
          <div>金点:{{ cardInfo.cash }}</div>
          <div>幸福豆:-</div>
        </div>
        <div class="mc_infoRight">
          <div>{{ cardInfo.cardNo || "--" }}</div>
          <div>有效期至{{ cardInfo.benefitExpireDate || "--" }}</div>
        </div>
      </div>
      <div class="mc_cardDefault">
        <div class="mc_let">默认健康卡</div>
        <div>
          <a-switch v-model="cardInfo.default" @change="changeDefault" />
        </div>
      </div>
      <div class="mc_cardDefault">
        <div class="mc_let" @click="purchaseRecord">消费记录</div>
      </div>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader";
import Card from "@/components/Card";
export default {
  components: {
    NavHeader,
    Card
  },
  data() {
    return {
      title: "管理健康卡",
      cardInfo: {
        cardNo: this.$route.params.cardNo,
        benefitExpireDate: "2000-00-00",
        cash: "",
        bigImageUrl: "",
        default: false
      }
    };
  },
  created() {
    this.getCardInfo();
  },
  mounted() {},
  methods: {
    async getCardInfo() {
      const params = {
        // cardNo: "5SV70NM44S138SH4",
        // merchantId: 200,
        cardNo: this.$route.params.cardNo,
        merchantId: this.$store.state.merchantId
      };
      await this.$service
        .post("/mc/api/user/card/benefit/list", params)
        .then(res => {
          if (res.data.resultCode === 0) {
            // this.$message.success("success");
            this.cardInfo = res.data.data;
          } else {
            this.$message.warning(res.data.resultMsg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    changeDefault(checked) {
      if (checked) {
        this.$service
          .get(
            "/mc/api/user/card/defaultSetting?cardNo=" +
              this.cardInfo.cardNo +
              "&merchantId=" +
              this.$store.state.merchantId
          )
          .then(res => {
            if (res.data.resultCode === 0) {
              this.$message.success(res.data.resultMsg);
            } else {
              this.cardInfo.default = false;
              this.$message.warning(res.data.resultMsg);
            }
          })
          .catch(err => {
            this.cardInfo.default = false;
            console.log(err);
          });
      } else {
        this.cardInfo.default = true;
        this.$message.info("请先设置另外一张卡为默认卡！");
      }
    },
    purchaseRecord() {
      const cardNo = this.cardInfo.cardNo;
      this.$router.push({
        name: "consumptionRecord",
        params: { cardNo }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.manage-healthCard {
  padding-top: 0.5rem;
  ::v-deep .mc-card {
    border-radius: 0;
  }
}
.mc_top {
  padding: 0.1rem 0 0.14rem 0.14rem;
  span {
    font-size: 0.26rem;
    font-weight: 600;
    color: #111111;
  }
}
.mc_middle {
  .mc_cardBox {
    // width: 100%;
    min-height: 2.35rem;
    // border: 0.01rem solid salmon;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .mc_cardInfo {
    display: flex;
    justify-content: space-between;
    padding: 0.14rem;
    .mc_infoLeft {
      font-size: 0.22rem;
      font-weight: 400;
      color: #111111;
      margin-left: 0.13rem;
    }
    .mc_infoRight {
      text-align: right;
      font-size: 0.1rem;
      font-weight: 400;
      color: #999999;
    }
  }
  .mc_cardDefault {
    display: flex;
    justify-content: space-between;
    padding: 0.14rem;
    border-bottom: 0.01rem solid #d8d8d8;
    &:last-child {
      border-bottom: none;
    }
    .mc_let {
      margin-left: 0.13rem;
    }
  }
  .mc_bot {
    border-bottom: none !important;
  }
}
</style>