<template>
  <div class="card-container" ref="card" @click="clickCard">
    <div
      class="mc-card"
      :style="`transform: scale(${scale});background-image: url(${
        value.bigImageUrl || value.backgroundImgUrl || ''
      });`"
      v-if="isNotEmpty() && notUseImage"
    >
      <!-- <div class="card-name">
        {{ value.cardName || value.skuName || value.goodsSkuName || "--" }}
      </div> -->
      <div class="card-no">
        {{ formatCardNo(value.cardNo) || "8888 8888 8888 8888" }}
      </div>
      <!-- <div class="card-owner">
        <div class="card-owner-1">持卡人姓名</div>
        <div class="card-owner-2">
          {{ value.customerName || userName || "--" }}
        </div>
      </div>
      <div class="card-time">
        <div class="card-time-1">有效期</div>
        <div class="card-time-2">
          {{ formatSaleExpirationTime(value.benefitExpireDate) || "01/27" }}
        </div>
      </div>
      <div class="card-icon">
        <img :src="value.smallImageUrl || ''" alt="图标" />
      </div> -->
    </div>
    <div v-else class="empty-card">
      <img :src="value.bigImageUrl || value.backgroundImgUrl" />
    </div>
  </div>
</template>
<script>
import { formatCardNo } from "@/helper";
import { isEmpty } from "lodash";

export default {
  name: "Card",
  data() {
    return { scale: 1, notUseImage: true };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    clickHandler:{
      type:Function,
      default:()=>()=>{}
    }
  },
  computed: {
    userName() {
      return (this.$store.state.userInfo || {}).accountName;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    formatCardNo,
    clickCard(){
      if(this.clickHandler){
        this.clickHandler()
      }
    },
    formatSaleExpirationTime(value = "") {
      const rst =
        (value.slice(5, 7) || "02") + "/" + (value.slice(2, 4) || "27");
      return rst;
    },
    isNotEmpty() {
      return !!this.value && !isEmpty(this.value);
    },
    resize() {
      if (!this.isNotEmpty() && this.notUseImage) return;
      const offsetWidth = this.$refs.card.offsetWidth;
      this.scale = offsetWidth / 320;
      //重新设置容器大小
      requestAnimationFrame(() => {
        this.$refs.card.style.width = 320 * this.scale + "px";
        this.$refs.card.style.height = 202 * this.scale + "px";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  width: 100%;
  .empty-card {
    img {
      width: 100%;
    }
  }
}
.mc-card {
  width: 320px;
  height: 202px;
  background-color: #000;
  color: #fff;
  border-radius: 0.1rem;
  position: relative;
  letter-spacing: 1px;
  transform-origin: 0 0;
  overflow: hidden;
  @include common-bg;
  .card-name {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .card-no {
    position: absolute;
    font-size: 16px;
    bottom: 44px;
    left: 30px;
    font-family: Farrington;
  }
  .card-owner {
    position: absolute;
    left: 20px;
    bottom: 20px;
    .card-owner-1 {
      font-size: 10px;
      margin-bottom: 0.03rem;
    }
    .card-owner-2 {
      font-size: 12px;
    }
  }
  .card-time {
    position: absolute;
    right: 20px;
    bottom: 20px;
    .card-time-1 {
      font-size: 10px;
      margin-bottom: 0.03rem;
    }
    .card-time-2 {
      font-size: 12px;
    }
  }
  .card-icon {
    position: absolute;
    border-radius: 30px;
    overflow: hidden;
    top: 20px;
    right: 20px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
